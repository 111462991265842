
.Profile-icon path {
    fill: white;
}
.Profile-icon:hover path {
    fill: var(--primary);
}

.Profile-icon:hover circle {
    fill: white;
}
