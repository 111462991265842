
.firebaseui-title {
    color: var(--primary);
    font-size: 24px;
}

.firebaseui-textfield {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.firebaseui-input {
    width: 100%;
    max-width: 250px;
    margin-left: 20px;
    font-family: var(--font-family);
    font-size: 14px;
}

.firebaseui-form-actions {
    margin-top: 15px;
}
.firebaseui-form-actions button {
    margin-right: 8px;
}

.firebaseui-button {
    font-family: var(--font-family);
    font-size: 14px;
    border-radius: 20px;
    border: 0;
    padding: 10px 20px;
    color: var(--contrastText);
    text-decoration: none;
    cursor: pointer;
    background: var(--grey1);
}
