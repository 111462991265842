
.Button {
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--primary);
    border: none;
    cursor: pointer;
    padding: 4px 20px 4px 20px;
    border-radius: 20px;
    width: max-content;
}

.Button .MuiTypography-root {
    color: var(--contrastText);
}

.Button:hover {
    background: var(--primaryDark);
}

.Button-outline {
    background: none;
    border: 2px solid var(--primary);
}
.Button-outline:hover {
    background: var(--primary);
}
.Button-outline .MuiTypography-root {
    color: var(--primary);
}
.Button-outline:hover .MuiTypography-root {
    color: var(--contrastText);
}

.Button-disabled {
    cursor: unset;
    opacity: 0.5;
    pointer-events: none;
    background: var(--primaryDark);
    border-color: var(--primaryDark);
}