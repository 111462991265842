:root {

  --background: #fff;
  --primary: #294238;
  --primaryLight: #85a99d;
  --primaryDark: #003421;
  --secondary: #a1cb88;
  --text: #282a2e;
  --contrastText: #fff;

  --grey1: #aaa;

  --font-family: Lexend, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;

  --shadow: drop-shadow(0px 20px 10px #00000024);
}


body {
  background: var(--background);

  margin: 0;
  font-family: var(--font-family);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
